import { Maybe } from 'types/graphql'

type SelectFormatActionType = 'toOption' | 'fromOption'

export type SelectOption = {
  label: string
  value: any
}

export type SelectDataType = Record<string, any> | Record<string, any>[]

export type SelectProps = {
  options: SelectOption | SelectOption[]
  onValueChange: (option: Maybe<SelectDataType>) => void
  isMulti?: boolean
  value?: any
}

const formatFields = (
  obj: Record<string, any>,
  action: SelectFormatActionType
) => {
  switch (action) {
    case 'fromOption':
      return {
        name: obj.label,
        id: obj.value,
      }
    case 'toOption':
      return {
        label: obj.name as string,
        value: obj.id,
      }
  }
}

export const convertSelectOptions = (
  data: SelectDataType,
  action: SelectFormatActionType,
  isMulti?: boolean | undefined
) => {
  if (!data) {
    return isMulti ? [] : null
  }

  if (isMulti && Array.isArray(data)) {
    return data.map((obj) => formatFields(obj, action))
  }

  if (!isMulti && !Array.isArray(data)) {
    return formatFields(data, action)
  }

  console.error('data and isMulti mismatch')
  return isMulti ? [] : null
}
