import { useFormikContext } from 'formik'
import { convertSelectOptions, SelectProps } from 'lib/selectHelpers'
import { VFC } from 'react'
import { useIntl } from 'react-intl'

import Select from './ReactSelect'

type SessionLocationSelectProps = SelectProps & {
  name: string
  options: Array<{
    id: string
    [x: string]: string | number | boolean
  }>
}

const SessionLocationSelect: VFC<SessionLocationSelectProps> = ({
  name,
  value,
  options,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { setFieldValue } = useFormikContext()

  const handleChange = (selection: Record<string, any>) => {
    setFieldValue(name, convertSelectOptions(selection, 'fromOption'))
  }

  const defaultValue = {
    value: null,
    label: formatMessage({ id: 'term.online' }),
  }

  return (
    <Select
      {...props}
      name={name}
      placeHolder={null}
      onChange={handleChange}
      options={[
        defaultValue,
        ...options.map((o) => convertSelectOptions(o, 'toOption')),
      ]}
      isSearchable={true}
      defaultValue={defaultValue}
      value={value ? convertSelectOptions(value, 'toOption') : defaultValue}
    />
  )
}

export default SessionLocationSelect
