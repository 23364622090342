import ErrorBoundary from 'components/ErrorBoundary'
import { validateValue } from 'lib/validate'
import { ComponentType } from 'react'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'

// Override focus styling
// https://github.com/JedWatson/react-select/issues/2728
const borderedStyles = {
  control: (base: Record<string, any>, state: Record<string, any>) => ({
    ...base,
    '&:hover': { borderColor: 'lightgray' },
    border: '1px solid lightgray',
    boxShadow: state.isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : 'none',
    padding: 2,
  }),
  menu: (provided: Record<string, any>) => ({
    ...provided,
    zIndex: 10,
  }),
}

const borderlessStyles = {
  control: (base: Record<string, any>) => ({
    ...base,
    '&:hover': { borderColor: 'lightgray' },
    border: '0px solid lightgray',
    boxShadow: 'none',
  }),
}

const Empty = () => null

const borderlessComponents = {
  ClearIndicator: Empty,
  DropdownIndicator: Empty,
  IndicatorSeparator: Empty,
}

type ControlTypeProps = {
  borderless?: boolean
  value?: any
  [x: string]: any
}

const createSelect = (Control: ComponentType<ControlTypeProps>) => {
  const select = ({ borderless, value, ...props }: ControlTypeProps) => {
    const styles = borderless ? borderlessStyles : borderedStyles
    const components = borderless ? borderlessComponents : {}

    if (!validateValue(value, props.allowValueAsObject)) {
      return (
        <div className="p-2 border border-red rounded text-red">
          Invalid value
        </div>
      )
    }

    return (
      <ErrorBoundary>
        <Control
          {...props}
          value={value}
          classNamePrefix="react-select"
          components={components}
          styles={styles}
          isDisabled={props.disabled}
        />
      </ErrorBoundary>
    )
  }

  select.displayName = Control.displayName

  return select
}

export const Select = createSelect(ReactSelect)
export const AsyncSelect = createSelect(ReactSelectAsync)

export default Select
